@.searchSiteHandler = (searchOptions) ->
    closeButton = null
    searchInput = null
    sortSelect = null

    $(document).ready(->
        searchResultsContainer = $('.global-search__results')
        if searchResultsContainer.length
            searchResultsContainer.css({ 'min-height': $(window).height() - searchResultsContainer.offset().top})
        assignElements()

        # generalnie zazwyczaj 1 to new tab, a 2 to search
        # TODO: a co jesli przyszedłem z innej strony?
        if history.length > 2
            closeButton.addClass('active')
        initSelects()
        initInfiniteScroll()

    )
    $(window).on('resize', ->
        searchResultsContainer = $('.global-search__results')
        if searchResultsContainer.length
            searchResultsContainer.css({ 'min-height': $(window).height() - searchResultsContainer.offset().top})
    )

    assignElements = ->
        closeButton = $('.global-search__header__close')
        searchInput = $('input[name=query]')
        sortSelect = $('#searchSortSelect')
    initSelects = ->
        searchInput.on('blur keyup', (event) ->
            if event.keyCode == 13
                parameter = $(this).val().replace(/[\/]/g, ' ').trim()
                if parameter
                    window.location.replace('/search/' + parameter + '/');
        )

        searchInput.autocomplete({
            serviceUrl: searchOptions.dataUrl
            triggerSelectOnValidInput: false
            minChars: searchOptions.minChars
            deferRequestBy: searchOptions.deferRequestBy
            onSelect: (suggestion) ->
                parameter = suggestion['value'].replace(/[\/]/g, ' ').trim()
                if parameter
                    window.location.replace('/search/' + parameter + '/');
        })

        sortSelect.select2(
            minimumResultsForSearch: Infinity
            width: '100%'
            dropdownParent: $('.global-search__sort-select')
        )

        closeButton.on('click', ->
            history.back()
        )


@.initInfiniteScroll = ->
    link = $('.count-page')
    loader = $('.global-search__loader')
    lastPage = link.data('lastpage')
    url = window.location.href;
    isLoading = false
    canLoadMore = true
    if canLoadMore && !!link.length
        $(window).on('scroll', ->
            page = link.data('page')
            t = $('.global-search__results').offset().top
            h = $('.global-search__results').height()
            ws = $(window).scrollTop()
            dh = $(document).height()
            wh = $(window).height()
            if dh - (wh + ws) < dh - (h + t)
                if not isLoading and page <= lastPage and page
                    isLoading = true
                    loader.addClass('active')
                    is_order = url.includes('order')
                    previous_page = page - 1
                    page_in_url = url.includes('page=')
                    if page_in_url
                        url = url.replace('page=' + previous_page, 'page=' + page)
                    else
                        if is_order
                            url = url + '&page=' + page
                        else
                            url = url + '?page=' + page
                    updatePaging(url, page)
        )

    updatePaging = (url, page) ->
        $.ajax({
            type: 'get'
            url: url,
            success: (data) ->
                template = $(data.template)
                console.log(data)
                if template == 0
                    canLoadMore = false
                    isLoading = false
                    loader.removeClass('active')
                    return

                template.insertBefore(loader)
                isLoading = false
                loader.removeClass('active')
                link.data('page', page + 1)
        })
